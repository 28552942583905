import React, { useState, useContext, useEffect, useRef } from 'react';
import { Box, Button, MenuItem } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@emotion/react';

function createData(memberId, name, school, country, email, inviteAccepted) {
    return {
      memberId,
      name,
      school,
      country,
      email,
      inviteAccepted,
    };
  }
  
  const members = [
      createData(1, 'Alice Johnson', 'Greenwood High School', 'USA', 'alice.johnson@example.com', true),
      createData(2, 'Bob Smith', 'Blue Valley High', 'Canada', 'bob.smith@example.com', false),
      createData(3, 'Carlos Garcia', 'Saint Mary School', 'Mexico', 'carlos.garcia@example.com', true),
      createData(4, 'David Lee', 'Sunshine School', 'Australia', 'david.lee@example.com', false),
      createData(5, 'Eva Zhang', 'Happy Valley School', 'China', 'eva.zhang@example.com', true),
      createData(6, 'Fatima Khan', 'Springfield School', 'UAE', 'fatima.khan@example.com', false),
      createData(7, 'George Brown', 'Oakwood High', 'UK', 'george.brown@example.com', true),
      createData(8, 'Hannah Martin', 'Elm Street School', 'Germany', 'hannah.martin@example.com', false),
      createData(9, 'Isabella Rossi', 'Florence Academy', 'Italy', 'isabella.rossi@example.com', true),
      createData(10, 'Jack Wilson', 'Pine Grove High', 'New Zealand', 'jack.wilson@example.com', false),
      createData(11, 'Kaito Tanaka', 'Tokyo International', 'Japan', 'kaito.tanaka@example.com', true),
      createData(12, 'Liam Murphy', 'Emerald Secondary', 'Ireland', 'liam.murphy@example.com', true),
      createData(13, 'Maria Lopez', 'Valley View School', 'Spain', 'maria.lopez@example.com', false),
      createData(14, 'Nina Petrov', 'Riverbank School', 'Russia', 'nina.petrov@example.com', true),
      createData(15, 'Oliver Evans', 'Westwood School', 'South Africa', 'oliver.evans@example.com', false)
    ];

    function getStyles(id, selectedMemberIds, theme) {
        return {
          fontWeight: selectedMemberIds.includes(id)
            ? theme.typography.fontWeightMedium
            : theme.typography.fontWeightRegular,
        };
      }

export default function ExistingClubProject({ projectType }) {
    const [teamDetailsDialog, setTeamDetailsDialog] = useState(false)
    const [errors, setErrors] = useState({});
    const theme = useTheme();
    const [selectedMemberIds, setSelectedMemberIds] = React.useState([]);
  

    const teamValuesRef = useRef({
        teamName:'',
        teamMembers:[]
    })
    const handleClickOpen = () => {
        setTeamDetailsDialog(true);
      };
    
      const handleClose = () => {
        setTeamDetailsDialog(false);
      };

      const handleChange = (e) => {
        const { name, value } = e.target;
        teamValuesRef.current[name] = value;
        setErrors((prev) => ({ ...prev, [name]: '' }));
      };

      const handleMemberSelection = (event) => {
        const { value } = event.target;
        teamValuesRef.current.teamMembers = value;
        setErrors((prev) => ({ ...prev, teamMembers: '' }));
      };

      const validateForm = () => {
        const newErrors = {};
        if (!teamValuesRef.current.teamName) newErrors.teamName = "Team Name is required";
        if (teamValuesRef.current.teamMembers?.length===0) newErrors.teamMembers = "Team Members are required";
        return newErrors;
      };
    
      const handleCreateClub = () => {
        setTeamDetailsDialog(false)
      }

      const handleSubmit = (e) => {
        e.preventDefault();
        const newErrors = validateForm();
        if (Object.keys(newErrors).length > 0) {
          setErrors(newErrors);
        } else {
          console.log(teamValuesRef.current);
          handleCreateClub()
        }
      };

    const CreateTeamDialog = (<Dialog
        disableEscapeKeyDown
        maxWidth='sm'
        open={teamDetailsDialog}
        onClose={(handleClose)}
        PaperProps={{
            component: 'form',
            onSubmit: handleSubmit,
        }}
    >
        <DialogTitle>Create you Team</DialogTitle>
        <DialogContent sx={{minWidth:'600px'}}>
            {/* <TextField
                autoFocus
                required
                margin="dense"
                id="name"
                name="email"
                label="Email Address"
                type="email"
                fullWidth
                // variant="standard"
            /> */}
            <TextField
                fullWidth
                minWidth={600}
                label="Team Name"
                name="teamName"
                id="teamName"
                margin="normal"
                defaultValue={teamValuesRef.current.teamName}
                onChange={handleChange}
                error={!!errors.teamName}
                helperText={errors.teamName}
              />
              <TextField
                fullWidth
                select
                label="Choose members"
                name="teamMembers"
                margin="normal"
                id="teamMembers"
                error={!!errors.teamMembers}
                helperText={errors.teamMembers}
                SelectProps={{
                    multiple: true,
                    value: teamValuesRef.current.teamMembers,
                    onChange: handleMemberSelection,
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: 300
                        }
                      }
                    }
                  }}
              >
                {members.map((member, index) => (
                  <MenuItem key={index} value={member.memberId} style={getStyles(member.memberId, teamValuesRef.current.teamMembers, theme)}>
                    {member.name}
                  </MenuItem>
                ))}
              </TextField>
        </DialogContent>
        <DialogActions sx={{p:3}}>
            <Button variant='text' onClick={handleClose}>Cancel</Button>
            <Button variant='contained' type="submit">Submit</Button>
        </DialogActions>
    </Dialog>)

    return (

        <Box>
            {CreateTeamDialog}
            <Box textAlign='center' mt={6}>
                {projectType === 'Individual' ? <Button
                    // component={RouterLink}
                    // to='/create-form'
                    variant="contained"
                // sx={{ width: '5.625rem' }}
                >
                    Participate
                </Button> :
                    <Button
                        // to='/create-form'
                        variant="contained"
                        // sx={{ width: '5.625rem' }}
                        onClick={handleClickOpen}
                    >
                        Create Team and Participate
                    </Button>
                }
            </Box>
        </Box>

    );
}
