import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";

export default function Updates({ updates }){
    return <Box sx={{ padding: 2 }}>
    {updates.length > 0 ? (
        <Grid container spacing={2}>
            {updates.map((update, index) => (
                <Grid item xs={12} key={index}>
                    <Card>
                        <CardContent>
                            <Typography variant="body1">
                                <strong>{update.person}</strong> ({update.timestamp}):
                            </Typography>
                            <Typography variant="body2" sx={{ marginTop: 1 }}>
                                {update.updateDescription}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    ) : (
        <Typography>No updates available.</Typography>
    )}
</Box>
}
