import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';

import ClubList from './ClubList';
import { ExploreTopics } from '../clubsColdStart/ExploreTopics';


const categories = ["Technology", "Finance", "Entrepreneurship", "Medicine", "Creative Writing", "Environment"];

export default function ClubsLanding({clubsIAmMemberOf,clubsNotAMemberOf}) {

  return (
    <Box sx={{ py: 6, px: { xs: 2, md: 12, lg: 18, xl: 32 } }}>
    <Typography variant='h5' mb={6} fontWeight={600}>
      My Clubs
    </Typography>
      
        {/* <ClubForm open={open} onClose={() => setOpen(false)} newClub={newClub} handleInputChange={handleInputChange} handleCreateClub={handleCreateClub} /> */}

          <>
            {/* {clubsIAmMemberOf?.length>0 && <ClubList clubs={clubsIAmMemberOf} title="Clubs I'm a Member Of" />} */}
            
            {/* Placehoder cards*/}
            {clubsNotAMemberOf?.length>0 && <ClubList clubs={clubsNotAMemberOf} title="Clubs I'm a Member Of" />}
            
            {clubsNotAMemberOf?.length>0 && <ExploreTopics data={clubsNotAMemberOf}/>}
            {/* <Typography> Explore clubs</Typography>
            <ClubList clubs={clubsNotAMemberOf} title="" /> */}
          </>
        
    </Box>
  );
}
