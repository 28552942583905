import React from 'react';
import { Grid, Card, Box, CardContent, Typography, Avatar, CardActions } from '@mui/material';

const Testimonials = ({ testimonials }) => {
  return (
    <Grid container rowSpacing={{ xs: 2, md: 4, xl: 5 }} columnSpacing={{ xs: 1, sm: 2, md: 4, xl: 5 }}>
      {testimonials.map((testimonial, index) => (
        <Grid item xs={12} sm={4} key={index} sx={{
          display: 'flex',
          justifyContent: { xs: 'center', md: 'flex-start' }, // center on xs, left on sm and larger
        }}>
          <Card sx={{ maxWidth: 390, height: 400 }}>
            <CardContent>
              <Box sx={{ textAlign: '-webkit-center', mb: 3 }}>
                <Avatar src={testimonial.image} sx={{ width: 100, height: 100 }}>P</Avatar>
              </Box>
              <CardActions sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Typography variant="h6" color="text.text">
                  {testimonial.user}
                </Typography>
                <Typography variant="body2" color="text.secondary" style={{ marginLeft: 0 }}>
                  {testimonial.role}
                </Typography>
                <Typography variant="body2" color="text.secondary" style={{ marginLeft: 0 }}>
                  {testimonial.location}
                </Typography>
              </CardActions>
              <Typography variant="body1" color="text.secondary">
                "{testimonial.description}"
              </Typography>
            </CardContent>

          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default Testimonials;
