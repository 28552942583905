import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

export default function Footer({footer}) {
  return (
    <Box
      component="footer"
      sx={{
        py: 2,
        px: 4, 
        mt: 'auto',
        mt: 'auto',
        color:(theme) =>
            theme.palette.primary.contrastText,
        backgroundColor: (theme) =>
          theme.palette.primary.main
      }}
    >
      <Container maxWidth="lg">
        <Typography variant="body2" align="left">
         {footer?.copyright?.text}
        </Typography>
      </Container>
    </Box>
  );
}
