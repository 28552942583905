import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { Button, DialogContent } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import MemberListDialog from './MemberListDialog';

function createData(memberId, name, school, country, email, inviteAccepted) {
  return {
    memberId,
    name,
    school,
    country,
    email,
    inviteAccepted,
  };
}

const rows = [
    createData(1, 'Alice Johnson', 'Greenwood High School', 'USA', 'alice.johnson@example.com', true),
    createData(2, 'Bob Smith', 'Blue Valley High', 'Canada', 'bob.smith@example.com', false),
    createData(3, 'Carlos Garcia', 'Saint Mary School', 'Mexico', 'carlos.garcia@example.com', true),
    createData(4, 'David Lee', 'Sunshine School', 'Australia', 'david.lee@example.com', false),
    createData(5, 'Eva Zhang', 'Happy Valley School', 'China', 'eva.zhang@example.com', true),
    createData(6, 'Fatima Khan', 'Springfield School', 'UAE', 'fatima.khan@example.com', false),
    createData(7, 'George Brown', 'Oakwood High', 'UK', 'george.brown@example.com', true),
    createData(8, 'Hannah Martin', 'Elm Street School', 'Germany', 'hannah.martin@example.com', false),
    createData(9, 'Isabella Rossi', 'Florence Academy', 'Italy', 'isabella.rossi@example.com', true),
    createData(10, 'Jack Wilson', 'Pine Grove High', 'New Zealand', 'jack.wilson@example.com', false),
    createData(11, 'Kaito Tanaka', 'Tokyo International', 'Japan', 'kaito.tanaka@example.com', true),
    createData(12, 'Liam Murphy', 'Emerald Secondary', 'Ireland', 'liam.murphy@example.com', true),
    createData(13, 'Maria Lopez', 'Valley View School', 'Spain', 'maria.lopez@example.com', false),
    createData(14, 'Nina Petrov', 'Riverbank School', 'Russia', 'nina.petrov@example.com', true),
    createData(15, 'Oliver Evans', 'Westwood School', 'South Africa', 'oliver.evans@example.com', false)
  ];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const headCells = [
    // {
    //   id: 'memberId',
    //   numeric: true,
    //   disablePadding: false,
    //   label: 'Member ID',
    // },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'school',
      numeric: false,
      disablePadding: false,
      label: 'School',
    },
    {
      id: 'country',
      numeric: false,
      disablePadding: false,
      label: 'Country',
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: 'Email',
    },
    {
      id: 'inviteAccepted',
      numeric: false,
      disablePadding: false,
      label: 'Invite Accepted',
    },
  ];  

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead >
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [openInviteDialog, setOpenInviteDialog] = React.useState(false);


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      [...rows]
        .sort(getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage],
  );

  return (
      <Paper sx={{  mb: 2, maxWidth:'100%'}}>
        <Box sx={{my:2, display:'flex'}}>
        <Button sx={{ml:'auto'}} variant="contained" startIcon={<PeopleIcon/>} onClick={()=>setOpenInviteDialog(true)}>
            Invite members
        </Button>
        </Box>
        {openInviteDialog && <MemberListDialog open={openInviteDialog} onClose={()=>setOpenInviteDialog(false)}/>}
        <TableContainer  sx={{ maxHeight: 440, maxWidth:'88vw' }}>
          <Table
          stickyHeader
            sx={{ minWidth: 600 }}
            aria-labelledby="tableTitle"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {

                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={row.id}
                    sx={{ cursor: 'pointer' }}
                  >
                    {/* <TableCell
                      component="th"
                      scope="row"
                      align="right"
                    >
                      {row.memberId}
                    </TableCell> */}
                    <TableCell sx={{display:'inline-flex',alignItems:'center',width:'100%'}}> <Avatar sx={{ bgcolor: blue[100], color: blue[600],mr:2 }}>
                  <PersonIcon />
                </Avatar>{row.name}</TableCell>
                    <TableCell>{row.school}</TableCell>
                    <TableCell>{row.country}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.inviteAccepted ? 'Yes' : 'No'}</TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
  );
}