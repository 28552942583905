import React from 'react';
import { Grid, Card, CardMedia, CardContent, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const CompetitionOverview = ({ competitions }) => {
  return (
    <Grid container rowSpacing={{ xs: 2, md: 4, xl:5 }} columnSpacing={{ xs: 1, sm: 2, md: 4,xl:5 }}>
      {competitions.map((competition, index) => (
        <Grid item xs={12} sm={4} key={index} sx={{
          display: 'flex',
          justifyContent: { xs: 'center', md: 'flex-start' }, // center on xs, left on sm and larger
        }}>
          <Link target="_blank" style={{ textDecoration: 'none'}} to={competition.link}>
          <Card sx={{ maxWidth: 390, height: 300 }}>
            <CardMedia
              component="img"
              height="140"
              image={competition.image}
              alt={competition.name}
            />
            <CardContent>
            <Typography variant="h6" color="text.secondary">
                {competition.name}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                {competition.description}
              </Typography>
            </CardContent>
          </Card>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
};

export default CompetitionOverview;
