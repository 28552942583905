import React, { useState, useEffect, useRef } from 'react';
import { Grid, ListItem, ListItemIcon, ListItemText,  Box } from '@mui/material';
import { styled } from '@mui/system';
import DoubleArrowSharpIcon from '@mui/icons-material/DoubleArrowSharp';

// Styled components for animation

const AnimatedBoxRight = styled(Box)(({ isVisible }) => ({
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? 'translateX(0)' : 'translateX(100%)',
    transition: 'all 1s ease',
}));

// Custom ListItem component
const CustomListItem = ({ children, isVisible, delay }) => {
    return (
        <ListItem
            disableGutters
            sx={{
                opacity: isVisible ? 1 : 0,
                transform: isVisible ? 'translateX(0)' : 'translateX(40px)',
                transition: `opacity 0.2s ease ${delay}ms, transform 0.2s ease ${delay}ms`,
            }}
        >
            <ListItemIcon>
                <DoubleArrowSharpIcon sx={{ color: "blue.main" }} />
            </ListItemIcon>
            <ListItemText>{children}</ListItemText>
        </ListItem>
    );
};

const Overview = (props) => {
    const [isVisible, setIsVisible] = useState(false);
    const [visibleItems, setVisibleItems] = useState([]); // Track visibility of individual items
    const containerRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                const entry = entries[0];
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            {
                root: null,
                threshold: 0.3, // Trigger when 30% of the element is visible
            }
        );

        if (containerRef.current) {
            observer.observe(containerRef.current);
        }

        return () => {
            if (containerRef.current) {
                observer.unobserve(containerRef.current);
            }
        };
    }, []);

    // Trigger the visibility of list items with a staggered effect
    useEffect(() => {
        if (isVisible) {
            props?.overview?.description?.forEach((_, index) => {
                setTimeout(() => {
                    setVisibleItems((prevItems) => [...prevItems, index]);
                }, index * 300);
            });
        }
    }, [isVisible]);

    return (
        <Box ref={containerRef} id="competition-overview">
            <AnimatedBoxRight
                isVisible={isVisible}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }} justifyContent='center' alignItems='center'>
                    {props?.overview?.description.map((competition, index) => (
                        <Grid item xs={12} sm={6} key={index} >
                            <CustomListItem
                                key={index}
                                isVisible={visibleItems.includes(index)}
                                delay={index * 10}
                            >
                                {competition}
                            </CustomListItem>
                        </Grid>
                    ))}
                </Grid>
            </AnimatedBoxRight>
        </Box>
    );
};

export default Overview;
