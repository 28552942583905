import React from 'react';
import { ClubBanner } from './ClubBanner';
import clubsPageData from "../../../data/clubsPageData.json";
import { ClubDetails } from './ClubDetails';
import { ExploreTopics } from './ExploreTopics';
import { Box } from '@mui/material';



export default function ClubsColdStart() {
  
  return (
    <>
    <ClubBanner data={clubsPageData?.banner}/>
    <Box sx={{ py:6,px: { xs: 2, md:12, lg: 18,xl:32 } }}>
      <ClubDetails data={clubsPageData?.advantages}/>
      <ExploreTopics data={clubsPageData?.topics}/>
    </Box>
    </>
  );
}
