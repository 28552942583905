import React, { useState, useEffect, useRef } from 'react';
import { TextField, MenuItem, Button, Container, Typography, Paper, Grid, Box } from '@mui/material';
import axios from 'axios';
import { API_CONFIG } from '../apiConfig.js';
import { useNavigate } from 'react-router-dom';

const teachers = [
  { name: 'Ms. Smith' },
  { name: 'Mr. Johnson' },
  { name: 'Mrs. Doe' },
];

const ClubForm = () => {
  const clubValuesRef = useRef({
    clubName: '',
    category: '',
    clubImageUrl: '',
    school: '',
    country: '',
    state: '',
    city: '',
    zipCode: '',
    teacherName: '',
    email: '',
  });

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate()

  useEffect(() => {
    axios.get(API_CONFIG.COUNTRIES_API)
      .then((response) => {
        setCountries(response.data.data);
      })
      .catch((error) => console.error("Error fetching countries:", error));
  }, []);

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    clubValuesRef.current.country = selectedCountry;
    setStates([]);
    setCities([]);
    setErrors((prev) => ({ ...prev, country: '' }));

    axios.post(API_CONFIG.STATES_API, { country: selectedCountry })
      .then((response) => {
        setStates(response.data.data.states);
      })
      .catch((error) => console.error("Error fetching states:", error));
  };

  const handleStateChange = (e) => {
    const selectedState = e.target.value;
    clubValuesRef.current.state = selectedState;
    setCities([]);
    setErrors((prev) => ({ ...prev, state: '' }));

    axios.post(API_CONFIG.CITIES_API, { country: clubValuesRef.current.country, state: selectedState })
      .then((response) => {
        setCities(response.data.data);
      })
      .catch((error) => console.error("Error fetching cities:", error));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    clubValuesRef.current[name] = value;
    setErrors((prev) => ({ ...prev, [name]: '' }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!clubValuesRef.current.clubName) newErrors.clubName = "Club Name is required";
    if (!clubValuesRef.current.category) newErrors.category = "Category is required";
    if (!clubValuesRef.current.school) newErrors.school = "School is required";
    if (!clubValuesRef.current.country) newErrors.country = "Country is required";
    if (!clubValuesRef.current.state) newErrors.state = "State is required";
    if (!clubValuesRef.current.city) newErrors.city = "City is required";
    if (!clubValuesRef.current.zipCode) newErrors.zipCode = "Zip Code is required";
    if (!clubValuesRef.current.teacherName) newErrors.teacherName = "Teacher Name is required";

    if (!clubValuesRef.current.email) {
      newErrors.email = "Email is required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(clubValuesRef.current.email)) {
      newErrors.email = "Invalid email format";
    }

    if (clubValuesRef.current.clubImageUrl && !/^https?:\/\/.*\.(jpeg|jpg|png|gif)$/i.test(clubValuesRef.current.clubImageUrl)) {
      newErrors.clubImageUrl = "Invalid URL format for Club Image (must end in .jpg, .jpeg, .png, or .gif)";
    }

    return newErrors;
  };

  const handleCreateClub = async () => {
    navigate('/clubs')

      // try {
      //   const docRef = await addDoc(collection(db, "clubs"), newClub);
      //   await setDoc(doc(db, "memberships", `${currentUser.id}_${docRef.id}`), {
      //     user: currentUser.id, clubId: docRef.id, role: "admin", dateJoined: new Date()
      //   });
      //   setClubs([...clubs, { ...newClub, id: docRef.id }]);
      //   setFilteredClubs([...clubs, { ...newClub, id: docRef.id }]);
      //   handleClose();  // Close modal after creating the club
      //   setNewClub({ name: '', url: '', category: '', admin: currentUser.id });  // Reset the form
      // } catch (error) {
      //   console.error("Error adding club:", error);
      // }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      console.log(clubValuesRef.current);
      handleCreateClub()
    }
  };

  return (
    <Container sx={{ py: 6 }} maxWidth="md">
      <Typography variant="h5" gutterBottom sx={{ pb: 6 }} fontWeight={600}>
        Create a new Club
      </Typography>
      <Paper sx={{ p: 6 }}>
        <form onSubmit={handleSubmit}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Club Name"
                name="clubName"
                defaultValue={clubValuesRef.current.clubName}
                onChange={handleChange}
                margin="normal"
                error={!!errors.clubName}
                helperText={errors.clubName}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Category"
                name="category"
                defaultValue={clubValuesRef.current.category}
                onChange={handleChange}
                margin="normal"
                error={!!errors.category}
                helperText={errors.category}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="School"
                name="school"
                defaultValue={clubValuesRef.current.school}
                onChange={handleChange}
                margin="normal"
                error={!!errors.school}
                helperText={errors.school}
              />
            </Grid>

            {/* Country Dropdown */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                select
                label="Country"
                name="country"
                onChange={handleCountryChange}
                margin="normal"
                error={!!errors.country}
                helperText={errors.country}
              >
                {countries.map((country) => (
                  <MenuItem key={country.country} value={country.country}>
                    {country.country}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {/* State Dropdown */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                select
                label="State"
                name="state"
                onChange={handleStateChange}
                margin="normal"
                error={!!errors.state}
                helperText={errors.state}
                disabled={!states.length}
              >
                {states.map((state, index) => (
                  <MenuItem key={index} value={state.name}>
                    {state.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {/* City Dropdown */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                select
                label="City"
                name="city"
                onChange={handleChange}
                margin="normal"
                error={!!errors.city}
                helperText={errors.city}
                disabled={!cities.length}
              >
                {cities.map((city, index) => (
                  <MenuItem key={index} value={city}>
                    {city}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Zip Code"
                name="zipCode"
                defaultValue={clubValuesRef.current.zipCode}
                onChange={handleChange}
                margin="normal"
                error={!!errors.zipCode}
                helperText={errors.zipCode}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                select
                label="Teacher Name"
                name="teacherName"
                defaultValue={clubValuesRef.current.teacherName}
                onChange={handleChange}
                margin="normal"
                error={!!errors.teacherName}
                helperText={errors.teacherName}
              >
                {teachers.map((teacher, index) => (
                  <MenuItem key={index} value={teacher.name}>
                    {teacher.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                type="email"
                defaultValue={clubValuesRef.current.email}
                onChange={handleChange}
                margin="normal"
                error={!!errors.email}
                helperText={errors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Club Image URL"
                name="clubImageUrl"
                defaultValue={clubValuesRef.current.clubImageUrl}
                onChange={handleChange}
                margin="normal"
                error={!!errors.clubImageUrl}
                helperText={errors.clubImageUrl}
              />
            </Grid>
          </Grid>
          <Box textAlign='center'>
            <Button type="submit" variant="contained" color="primary" style={{ marginTop: '16px', mx: 'auto', width: 200 }}>
              Submit
            </Button>
          </Box>
        </form>
      </Paper>
    </Container>
  );
};

export default ClubForm;
