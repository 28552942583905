import React, { useState, useContext } from 'react';
import { Link, useNavigate, Navigate } from 'react-router-dom';
import { Avatar, Button, CssBaseline, TextField, FormControlLabel, Checkbox, Grid, Box, Typography, Container } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AuthContext } from './Auth';
import SnackbarComponent from '../Alerts/SnackbarComponent';
import { getErrorMessage } from '../Helper';

const theme = createTheme();



export default function SignIn() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState('');
  const [severity, setSeverity] = useState('success');
  const { currentUser, setCurrentUser } = useContext(AuthContext); // Assuming your context manages user state

  if (currentUser) {
    return <Navigate to="/" />;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch('https://www.roundpier.com/api/auth/token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        throw new Error('Authentication failed');
      }
      if (response.ok) {
        console.log("OK AUth")
      }

      const data = await response.json();


      // Store the token and user info in localStorage or context
      const { key, user } = data.response;
      localStorage.setItem('token', key);  // Store token in localStorage
      localStorage.setItem('user', JSON.stringify(user));  // Store user info for future reference
      // console.log("token", key)

      // // Optionally, you can set the current user in the AuthContext if you're using it to manage the session
      // setCurrentUser(user);
      // Now, fetch the user's profile using the token
      const profileResponse = await fetch(`https://www.roundpier.com/api/user?token=${key}`);

      if (!profileResponse.ok) {
        throw new Error('Failed to fetch user profile');
      }

      const profileData = await profileResponse.json();

      // Store the profile in localStorage
      localStorage.setItem('profile', JSON.stringify(profileData));

      // setSnackbarMsg('Successfully signed in!');
      // setSeverity('success');
      // setSnackbarOpen(true);

      // // Redirect after login
      navigate("/clubs");
      // console.log("token2", key)


    } catch (error) {
      const message = getErrorMessage(error); // You can customize getErrorMessage to handle the errors accordingly
      setSnackbarMsg(message);
      setSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        {snackbarOpen && <SnackbarComponent snackbarOpen={snackbarOpen} snackbarMsg={snackbarMsg} severity={severity} handleSnackbarClose={handleSnackbarClose} />}
        <CssBaseline />
        <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">Sign in</Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              autoComplete="email"
              autoFocus
              onChange={(event) => setEmail(event.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(event) => setPassword(event.target.value)}
            />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>Sign In</Button>
            <Grid container>
              <Grid item>
                <Link to="https://www.roundpier.com/#sign_up_modal" style={{ textDecoration: 'none' }}>{"Don't have an account? Sign Up"}</Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
