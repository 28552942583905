import firebase from "firebase/compat/app";
import "firebase/auth";
// import "firebase/database";
// import "firebase/storage";
console.log("process.env.apiKey", process.env.REACT_APP_FIREBASE_KEY)
var config = {
  apiKey: "AIzaSyCol5NeXLh2e2qonPFGZ6G298C3TdOCUAo",
  authDomain: "roundpier-clubs.firebaseapp.com",
  projectId: "roundpier-clubs",
  storageBucket: "roundpier-clubs.appspot.com",
  messagingSenderId: "933953566858",
  appId: "1:933953566858:web:33cd66eefcdc247b921573",
  measurementId: "G-4BVVWL9MEW"
};
firebase.initializeApp(config);


export default firebase;