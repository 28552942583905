import React from 'react';
import { Box, CssBaseline } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Navigation from '../NavBars/Navigation';

const Layout = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Navigation />
      <Box component="main" sx={{ flexGrow: 1 }}>
        <Outlet /> 
      </Box>
    </Box>
  );
};

export default Layout;
