import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import { Button, CardActions, CardMedia, IconButton, Tooltip } from "@mui/material";
import ShareIcon from '@mui/icons-material/Share';
import { Link as RouterLink } from 'react-router-dom';

export default function Projects({ projects }) {
    return <Box sx={{ padding: 2 }}>
        {projects.length > 0 ? (
            <Grid container spacing={2}>
                {projects.map((project, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card sx={{ maxWidth: 345 }}>
                            <CardMedia
                                sx={{ height: 140 }}
                                image={project.image}
                                title="green iguana"
                            />
                            <CardContent>
                                <Box display='flex'>
                                <Typography gutterBottom variant="h6" >
                                    {project.title}
                                </Typography>
                                <Tooltip title="Share">
                                <IconButton
                                    aria-label="share"
                                    sx={{ml:'auto'}}
                                    >
                                    <ShareIcon />
                                    </IconButton>
                                    </Tooltip>
                                </Box>
                                
                                <Typography  sx={{ color: 'text.secondary' }}>
                                    <table>
                                        <tr><td>Start Date&nbsp;</td><td style={{ color: 'text.primary' }}>:&nbsp;&nbsp;{project.startDate}</td></tr>
                                        <tr><td>End Date&nbsp;</td><td>:&nbsp;&nbsp;{project.endDate}</td></tr>
                                        <tr><td>Project Type&nbsp;</td><td>:&nbsp;&nbsp;{project.projectType}</td></tr>
                                    </table>
                                    
                                
                                   {/* <p><span style={{ color: 'text.secondary' }}>Start Date: </span>{project.startDate}</p>
                                   <p><span style={{ color: 'text.secondary' }}>End Date: </span>{project.endDate}</p> */}
                                   
                                   
                                </Typography>
                                <Box display='flex'>
                                <Button 
                                    component={RouterLink}
                                    to={`/project/${project.id}?role=enroll`}
                                    variant="contained" 
                                    sx={{mt:2,mx:'auto'}} >Enroll</Button>
                                </Box>
                            </CardContent>
                       
                        </Card>
                    </Grid>
                ))}
            </Grid>
        ) : (
            <Typography>No projects available.</Typography>
        )}
    </Box>
}