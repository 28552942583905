import React, { useState, useContext, useEffect } from 'react';
import { Box, Button, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, Paper } from '@mui/material';
import { collection, getDocs, addDoc, setDoc, doc, query, where } from "firebase/firestore";
import { db } from "../firebase";

import { Grid, Card, CardMedia, CardContent, Typography } from '@mui/material';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import topicsData from "../data/topicsData.json";
import { Link as RouterLink } from 'react-router-dom';
import ExistingClubProject from '../components/project/ExistingClubProject';
import NewClubProject from '../components/project/NewClubProject';
import { EnhancedTable } from '../components/memberClubDetails/MemberListDialog';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';


export default function Project() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search)
  const role = queryParams.get("role");
  const [openDialog, setOpenDialog] = useState(false)

  const MemberListDialog = () => {
    const handleClose = () => {
      setOpenDialog(false);
    };
    return <Dialog onClose={handleClose} open={openDialog} maxWidth='md'>
      <DialogTitle>Team members</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={(theme) => ({
          position: 'absolute',
          right: 8,
          top: 8,
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <EnhancedTable/>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button variant='outlined' onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  }

  return (
    <Box sx={{ py: 6, px: { xs: 2, md: 12, lg: 18, xl: 32 } }}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box textAlign="center" flexGrow={1}>
          <Typography variant="h5" fontWeight={600}>
            Poetry Project
          </Typography>
          <Typography mb={6}>Create a poem on Environment</Typography>
        </Box>
        {role === 'enroll' && <Button onClick={()=>setOpenDialog(true)} variant="text">Show Members</Button>}
        {openDialog && <MemberListDialog/>}
      </Box>
      <Paper sx={{ p: 6 }}>
        <Typography variant='h6' gutterBottom fontWeight={600}>
          Project Background
        </Typography>
        <Typography variant='p'>
          Subcontractor comparisons are used to ensure accurate estimates of subcontractor quotes. It is often necessary to obtain tenders from various subcontractors for packages of work required on a construction project. To get the best value for money, it is vital to have a procedure in place to sort, compare and analyse tenders.
        </Typography>
        <Typography variant='p'>
          Subcontractor comparison systems list each tender received against its scope of works in order to compare the tenders and help the team make the best decision about choosing a subcontractor.
          An effective subcontractor comparison will include the following:
          <ul>
            <li>A structured list of competing subcontractors</li>
            <li> Various tenders received from relevant subcontractors.</li>
            <li>Values of tenders against scope of works.</li>
            <li>Different types of pricing details e.g. schedule of rates or lump sum price.</li>
            <li>Systematic reporting.</li>
            <li>A further breakdown of the selected subcontractors incorporating contingencies and any further allowances and exclusions.</li>
          </ul>
        </Typography>
        <Typography variant='p'>
          A successful subcontractor comparison will:
          <ul>
            <li>Aid in accurate decision-making process</li>
            <li>Provide accurate cost comparisons</li>
          </ul>
        </Typography>
        <Typography variant='p'>
          This task aims to assess your ability to read and interpret tenders and to familiarise you with subcontractor comparisons.
        </Typography>
        <Typography variant='h6' gutterBottom my={2} fontWeight={600}>
          Project Background
        </Typography>
        <Typography variant='p'>
          To help complete your task you will be provided with a subcontractor rates comparison sheet and a subcontractor tender.
          After reviewing and entering the tender into the spreadsheet you will need to calculate the overall costs for each subcontractor and make a recommendation to your Operations Manager on which subcontractor to award the Concrete works package to for the New Bridge Project. You will need to consider rates, inclusions and exclusions and other relevant information to make this decision.
          Your recommendation should be about 1 – 2 pages.
        </Typography>
        <p>{queryParams}</p>
        {role === 'enroll' ? <ExistingClubProject /> : <NewClubProject />}
      </Paper>
    </Box>

  );
}
