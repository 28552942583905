import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const Confirmation = ({ openConfirm, setOpenConfirm, confirmAction, message }) => {
  const handleClose = () => {
    setOpenConfirm(false);
  };

  return (
    <Dialog open={openConfirm} onClose={handleClose}>
      <DialogTitle>Confirm Action</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => { confirmAction(); handleClose(); }} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Confirmation;
