import React from 'react';
import { Grid, Card, CardMedia } from '@mui/material';

// Image paths
const imagePaths = [
  "../img/Image 2 for page.jpg",
  "../img/4.png",
  "../img/2022 script.jpg",

];

const WinnersCards = () => {
  return (
    <Grid container rowSpacing={{ xs: 2, md: 4, xl: 5 }} columnSpacing={{ xs: 1, sm: 2, md: 4, xl: 5 }}>
      {imagePaths.map((image, index) => (
        <Grid item xs={12} sm={4} key={index} sx={{
          display: 'flex',
          justifyContent: { xs: 'center', md: 'flex-start' }, // center on xs, left on sm and larger
        }}>
          <Card raised sx={{ maxWidth: 350, height: 285 }}>
            <CardMedia
              component="img"
              height="285"
              image={image}
              alt={`Winner ${index + 1}`}
            />
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default WinnersCards;
