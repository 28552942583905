import React, { useState, useContext, useEffect } from 'react';
import ClubsLanding from '../components/clubs/clubsLanding/ClubsLanding';
import ClubsColdStart from '../components/clubs/clubsColdStart/ClubsColdStart';
import { collection, getDocs, addDoc, setDoc, doc, query, where } from "firebase/firestore";
import Loading from "../components/commonComponents/Loading";
import { db } from "../firebase"; 



const categories = ["Technology", "Finance", "Entrepreneurship", "Medicine", "Creative Writing", "Environment"];

export default function Clubs() {
  const token = localStorage.getItem('token');
  console.log("currentUser token: ", token)

  const profile = JSON.parse(localStorage.getItem('profile'));

  const currentUser = profile.response.user;
  
  const [filteredClubs, setFilteredClubs] = useState([]);
  const [clubs, setClubs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userMemberships, setUserMemberships] = useState([]);

  console.log("currentUser token: ", currentUser)

  useEffect(() => {
    const fetchClubs = async () => {
      setLoading(true);
      try {
        const clubSnapshot = await getDocs(collection(db, "clubs"));
        const clubList = clubSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setClubs(clubList);
        setFilteredClubs(clubList);

        const membershipsSnapshot = await getDocs(query(collection(db, "memberships"), where("user", "==", currentUser?.id)));
        setUserMemberships(membershipsSnapshot.docs.map(doc => doc.data()));
      } catch (error) {
        console.error("Error fetching clubs or memberships:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchClubs();
  }, [currentUser?.id]);

  

  const clubsIAmMemberOf = clubs.filter(club => userMemberships.some(membership => membership.clubId === club.id));
  const clubsNotAMemberOf = clubs.filter(club => !userMemberships.some(membership => membership.clubId === club.id));

  if(currentUser )  return (loading ? <Loading /> : <ClubsLanding clubsIAmMemberOf={clubsIAmMemberOf} clubsNotAMemberOf={clubsNotAMemberOf}/>)
  
  else return <ClubsColdStart/>
  


}
