import React from "react";
import { Link as RouterLink } from 'react-router-dom';
import {  Box, Typography, Container, Button } from '@mui/material';
import { StyledButton } from "../../commonComponents/StyledComponents";
export const ClubBanner = (props) => {

  const handleLearnMoreClick = () => {
    var element = document.getElementById("topics-list");
    if(element){
      element.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
    }
  }

  return (
    <header>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '32rem',
          backgroundImage: `url(${props.data?.image})`, // Add the image here
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          color: '#fff',
          textAlign: 'center',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Overlay color
          }}
        />
        <Container maxWidth="md" sx={{ zIndex: 1, display: 'flex', flexDirection:'column', alignItems:'center', height:'100%', maxWidth:{lg:'1000px', xl:'1400px'} }}>
          <Box sx={{marginY:'auto'}}>
            <StyledButton
             component={RouterLink}
              size="large"
              to='/create-form'
             >
              Create Club
            </StyledButton>
          </Box>
          <Box sx={{display:'flex',justifyContent:'space-between',alignItems:'end',width:'100%'}}>
          <Box sx={{padding:2, borderLeft:'3px solid #EF5350', textAlign:'start',maxWidth:'50%'}}>
               <Typography variant="h6"> {props.data?.title} </Typography>
               <Typography variant="p"> {props.data?.description} </Typography>
          </Box>
          <Box sx={{padding:2}}>
            <Button variant="text" onClick={handleLearnMoreClick}>Learn More</Button>
          </Box>
          </Box>
        </Container>
      </Box>
    </header>
  );
};
