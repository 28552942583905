// firebase.js or firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

console.log("process.env.apiKey", process.env.REACT_APP_FIREBASE_KEY);

var firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY || "AIzaSyCol5NeXLh2e2qonPFGZ6G298C3TdOCUAo",
  authDomain: "roundpier-clubs.firebaseapp.com",
  projectId: "roundpier-clubs",
  storageBucket: "roundpier-clubs.appspot.com",
  messagingSenderId: "933953566858",
  appId: "1:933953566858:web:33cd66eefcdc247b921573",
  measurementId: "G-4BVVWL9MEW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export { db };
