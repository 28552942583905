import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

export const StyledButton = styled(Button)(({ theme }) => ({
    color: theme.palette.violet.contrastText,
    borderRadius: '1.125rem',
    backgroundImage: `linear-gradient(to right, ${theme.palette.violet.main} 20%, ${theme.palette.blue.main} 100%)`,
    backgroundColor: theme.palette.violet.main,
    backgroundSize: '100%',
    transition: 'background-color 2s ease',
    '&:hover': {
      backgroundImage: 'linear-gradient(to right, rgba(0,0,0,0) 20%, rgba(0,0,0,0) 100%)',
      backgroundColor: theme.palette.violet.main,
      color: theme.palette.violet.contrastText,
    }
  }));

  export const StyledLinkButton = styled(Button)(({ theme }) => ({
    color: theme.palette.violet.contrastText,
    borderRadius: '1.125rem',
    background: 'transparent', 
     '&:focus':{
        background: 'transparent', 
     },
    '&:hover': {
        background: 'transparent', 
        fontWeight:'bolder',
        textDecoration:'underline',
        color: theme.palette.violet.contrastText,
    },
  }));