import React, { useEffect, useState, useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Sidenav from "../components/NavBars/Sidenav";
import ResponsiveAppBar from "../components/NavBars/ResNav";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { collection, doc, getDoc, setDoc, Timestamp, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import { AuthContext } from "../components/Auth/Auth";
import ClubHeader from "../../src/components/memberClubDetails/ClubHeader";
import ClubTabs from "../../src/components/memberClubDetails/ClubTabs";

export default function MemberClubDetails() {
  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  // const id = queryParams.get('id');
  const {id} = useParams()
  // const { currentUser } = useContext(AuthContext);
  const profile = JSON.parse(localStorage.getItem('profile'));

  const currentUser = profile.response.user;
  const [clubData, setClubData] = useState(null);
  const [isMember, setIsMember] = useState(false);
  const [members, setMembers] = useState([]);

  useEffect(() => {
    const fetchClubData = async () => {
      const clubDoc = doc(db, "clubs", id);
      const clubSnapshot = await getDoc(clubDoc);
      if (clubSnapshot.exists()) {
        setClubData(clubSnapshot.data());
      } else {
        console.error("No such document!");
      }
    };

    const checkMembership = async () => {
      if (currentUser) {
        const membershipsCollection = collection(db, "memberships");
        const membershipsQuery = query(membershipsCollection, where("user", "==", currentUser.id), where("clubId", "==", id));
        const membershipsSnapshot = await getDocs(membershipsQuery);
        setIsMember(!membershipsSnapshot.empty);
      }
    };

    const fetchMembers = async () => {
      const membershipsCollection = collection(db, "memberships");
      const membersQuery = query(membershipsCollection, where("clubId", "==", id));
      const membersSnapshot = await getDocs(membersQuery);
      const memberEmails = membersSnapshot.docs.map(doc => doc.data().user);
      setMembers(memberEmails);
    };

    fetchClubData();
    checkMembership();
    fetchMembers();
  }, [id]);

  const handleJoinClub = async () => {
    if (currentUser && clubData) {
      const membershipRef = doc(db, "memberships", `${currentUser.id}_${id}`);
      await setDoc(membershipRef, {
        user: currentUser.id,
        clubId: id,
        dateJoined: Timestamp.fromDate(new Date())
      });
      setIsMember(true);
      setMembers(prevMembers => [...prevMembers, currentUser.id]);
    }
  };

  if (!clubData) return <Typography>Loading...</Typography>;

  return (
    <Box sx={{ py:6, px: { xs: 2, md: 12, lg: 18, xl: 32 } }}>
          <Paper sx={{ padding: 3 }} elevation={0}>
            <ClubHeader
              clubData={clubData}
              currentUser={currentUser}
              isMember={isMember}
              handleJoinClub={handleJoinClub}
            />

            <ClubTabs
              members={members}
              clubData={clubData}
              clubId = {id}
            />
          </Paper>
      </Box>
  );

  
}
