import React, { useState, useContext, useEffect } from 'react';
import { Box, Button, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText } from '@mui/material';
import { collection, getDocs, addDoc, setDoc, doc, query, where } from "firebase/firestore";
import { db } from "../firebase";

import { Grid, Card, CardMedia, CardContent, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import topicsData from "../data/topicsData.json";
import { Link as RouterLink } from 'react-router-dom';

export default function Topics() {

  return (

    <Box sx={{ py: 6, px: { xs: 2, md: 12, lg: 18, xl: 32 } }}>
      <Typography variant='h5' mb={6} fontWeight={600}>
        Explore Roundpier Topics
      </Typography>
      <Grid container rowSpacing={{ xs: 2, md: 4, xl: 5 }} columnSpacing={{ xs: 1, sm: 2, md: 4, xl: 5 }}>
        {topicsData.map((topic, index) => (
          <Grid item xs={12} sm={4} key={index} sx={{
            display: 'flex',
            justifyContent: { xs: 'center', md: 'flex-start' }, // center on xs, left on sm and larger
          }}>
            <Card sx={{ maxWidth: 300, height: 340 }}>
              <CardMedia
                component="img"
                height="140"
                image={topic.image}
                alt={topic.name}
              />
              <CardContent sx={{ height: 'calc(100% - 140px)', display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h6" color="text.secondary" textAlign='center' gutterBottom>
                  {topic.name}
                </Typography>
                <Typography variant="body1" color="text.secondary" gutterBottom>
                  {topic.description}
                </Typography>
                <Box textAlign='center' mt='auto'>
                  <Button

                    component={RouterLink}
                    to={`/project/${topic.id}?role=new`}
                    variant="contained"
                  // sx={{ width: '5.625rem' }}
                  >
                    Participate Now
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>

  );
}
