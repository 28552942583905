import React from "react";
import { Box, Typography, Avatar } from '@mui/material';
import { Grid, Card, CardMedia, CardContent } from '@mui/material';
import { Link } from 'react-router-dom';

export const ExploreTopics = (props) => {

  return (
    < >
    <Typography variant="h6" marginBottom={3} >Explore Roundpier</Typography>
 
    <Grid container  rowSpacing={{ xs: 2, md: 4, xl:5 }} columnSpacing={ 1 } >
      {props.data?.map((topic, index) => (
        <Grid item xs={12} sm={6} md={4}  xl={3} key={index} sx={{
          display: 'flex',
          justifyContent: { xs: 'center', md: 'flex-start' }, // center on xs, left on sm and larger
        }}>
          <Link 
          // target="_blank" 
          style={{ textDecoration: 'none' }}
           to={`/club-topics/${topic.id}`}
          >
            <Card sx={{ p:2,width: 200, height: 200 }}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Avatar
                  src={topic.image}
                  alt={topic.name}
                  sx={{ width: 80, height: 80 }}
                />
              </Box>
              <CardContent sx={{textAlign:'center'}}>
                <Typography variant="h6" color="text.secondary">
                  {topic.name}
                </Typography>
                <Typography variant="body" color="text.secondary">
                  {topic.category}
                </Typography>
              </CardContent>
            </Card>
          </Link>
        </Grid>
      ))}
    </Grid>
    </>
  );
}
