import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import { collection, getDocs, addDoc, setDoc, doc, query, where } from "firebase/firestore";
import { userId, get, post } from "../Helper";
import { db } from "../../firebase";
import Members from "./MemberList";
import Projects from "./ProjectList";
import Updates from "./Updates";
import projectListData from "../../data/projectListData.json";

function ClubTabs({ members, clubData, clubId }) {
    const [name, SetName] = useState([]);
    const [projects, setClubProjects] = useState([]);
    const profile = JSON.parse(localStorage.getItem('profile'));
    const currentUser = profile.response.user
    const [tabValue, setTabValue] = useState(0);

    useEffect(() => {
        const fetchClubs = async () => {
            try {
                const clubSnapshot = await getDocs(collection(db, "club-projects"));
                const clubProjectList = clubSnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .filter(doc => doc.club === clubId); // Filter by clubId

                setClubProjects(clubProjectList);
            } catch (error) {
                console.error("Error fetching clubs or memberships:", error);
            } finally {
                console.log(true);
            }
        };

        fetchClubs();
    }, [currentUser.id, clubId]); // Make sure to include clubId in the dependency array

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
      };

    const updates = [
        {
            timestamp: "2024-09-10 10:30 AM",
            person: "Roundpier",
            updateDescription: "Sample website for guidance: www.feedcapture.com"
        },
        {
            timestamp: "2024-09-10 10:30 AM",
            person: "PZ",
            updateDescription: "The link for making nice websites: https://www.w3schools.com/"
        },
        ,
        {
            timestamp: "2024-09-10 10:30 AM",
            person: "Roundpier",
            updateDescription: "Help for project Hackathon: https://www.youtube.com/watch?v=lwz5bMN8jX8&list=RDATl2X&index=4"
        }
    ];

    useEffect(() => {
        get("/user/view/98954?token=98954_2785cbfc7a5a89f7d74958b1befcb7ee")
            .then((data) => {
                SetName(data.response.user.name);
            })
            .catch((error) => console.error(error));
    }, []);

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 2 }}>
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="club tabs">
                    <Tab label="Members" />
                    <Tab label="Projects" />
                    <Tab label="Updates" />
                </Tabs>
            </Box>

            {/* Members Tab */}
            {tabValue === 0 && (
                <Members members={members} />
            )}

            {/* Projects Tab */}
            {tabValue === 1 && (
                <Projects projects={projectListData}/>
            )}

            {/* Updates Tab */}
            {tabValue === 2 && (
                <Updates updates={updates}/>
            )}
        </>
    );
}


export default ClubTabs;
