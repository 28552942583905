import React, { useState, useContext, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

export default function NewClubProject() {
  
  return (

    <Box>
        <Box textAlign='center' mt={6}>
          <Button 
            component={RouterLink}
            to='/create-form'
            variant="contained"
            // sx={{ width: '5.625rem' }}
            >
                Create your club and participate
            </Button>
          </Box>
    </Box>

  );
}
